import { container, title } from "assets/jss/material-kit-react.jsx";

const pillsStyle = {
  section: {
    padding: "40px 0"
  },
  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  homeimage: {
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  imagewrap: {
    width: "100%",
    height: "auto",
    "@media (max-width: 800px)": {
      width: "100%",
      height: "250px",
    }
  },
  headinginverse: {
    marginTop: "-2px",
    fontSize: "3.4rem",
    fontWeight: "800",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#0b292c",
    marginBottom: "4rem",
    marginLeft: "25%",
  },
  preheadinginverse: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    color: "#0b292c",
    opacity: "0.9",
    marginTop: "10%",
    marginBottom: "5px",
    marginLeft: "25.5%",
  },
  parawrapinverse: {
    position: "relative",
    marginLeft: "25%",
    marginRight: "40px",
  },
  paratextinverse: {
    fontSize: "16px",
    marginBottom: "1.2rem",
  },
  paddingone: {
    paddingBottom: "5rem",
    "@media (max-width: 1000px)": {
      paddingBottom: "2rem",
    }
  },
  mainwrap: {
    margin: "auto",
    maxWidth: "2100px",
  },
  paddinghalf: {
    paddingBottom: "2rem",
  },
  of: {
    overflow: "hidden",
  }, 
  buttonwrap: {
position: "relative",
  },
};

export default pillsStyle;
